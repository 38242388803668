<template>
  <div class="page-wrap gov-model">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">金融行业智能客服解决方案</div>
          <div class="desc">为银行、保险、证券等金融业务场景，提供基于深度学习和自然语言处理等技术的一体化智能客服解决方案。面向服务、营销和数据维度，高效的解决客户咨询、投诉、业务办理等冗杂耗时的业务需求，提升金融服务窗口业务应答和业务办理效率。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 行业痛点 -->
    <div class="section bg-white pain">
      <div class="title">行业痛点</div>
      <div class="content">
        <div class="pain-list">
          <div
              class="item"
              :class="{'border-top': index >=3 && index <= 5, 'border-r': index != 2 && index != 5}"
              v-for="(item, index) in painList"
              :key="index"
          >
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 整体框架 -->
    <div class="section frame">
      <div class="title">整体框架</div>
      <div class="content">
        <div class="bg-frame"></div>
      </div>
    </div>

    <!-- 服务模式 -->
    <div class="section bg-white service">
      <div class="title">服务模式</div>
      <div class="sub-title">全渠道、全流程、智能化的人机协同服务模式</div>
      <div class="content">
        <div class="bg-service"></div>
      </div>
    </div>

    <!-- 平台架构 -->
    <div class="section arc">
      <div class="title">平台架构</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 核心优势 -->
    <div class="section bg-white adv">
      <div class="title">核心优势</div>
      <div class="content">
        <div class="adv-list">
          <div class="item" v-for="item in advList" :key="item.name">
            <div class="top">
              <div class="icon">
                <img :src="getIcon(item)"/>
              </div>
              <div class="title">{{item.name}}</div>
            </div>
            <div class="info">
              <div class="list">
                <div class="item" v-for="(info, infoIndex) in item.list" :key="infoIndex">
                  <div class="title">{{info.title}}</div>
                  <div class="desc">{{info.desc}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户案例 -->
<!--    <div class="section case">-->
<!--      <div class="title">客户案例</div>-->
<!--      <div class="content">-->
<!--        <div class="bg-case">-->
<!--          <img src="@/assets/icss-sfi/logo-case.png" class="logo" />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      painList: [
        {
          name: '',
          icon: 'icon-pain-1.png',
          content: '金融行业业务增长迅速，智能化金融服务需求强烈',
        },
        {
          name: '',
          icon: 'icon-pain-2.png',
          content: '金融销售过程难以把控，线索流转复杂，敏感词层出不穷',
        },
        {
          name: '',
          icon: 'icon-pain-3.png',
          content: '金融对业务知识点要求性高，客服 培训成本高',
        },
        {
          name: '',
          icon: 'icon-pain-4.png',
          content: '多种咨询接入渠道，数据无法打通与统一',
        },
        {
          name: '',
          icon: 'icon-pain-5.png',
          content: '交互数据处理难，无法进行精细化运营',
        },
        {
          name: '',
          icon: 'icon-pain-6.png',
          content: '大量用户的回访，人为外呼效率低',
        }
      ],
      advList: [
        {
          name: '技术优势',
          icon: 'icon-adv-1.png',
          list: [
            {
              title: '识别技术',
              desc: '优秀的语音识别引擎，支持智能打断、短词识别等，金融领域识别率达90%以上'
            },
            {
              title: '合成技术',
              desc: '语音合成引擎，提供多种高保真音库适用各种业务场景，支持录音'
            },
            {
              title: '语义技术',
              desc: '支持多轮交互，精准识别用户意图，灵活应答'
            }
          ]
        },
        {
          name: '产品易用',
          icon: 'icon-adv-2.png',
          list: [
            {
              title: '流程配置简单易上手',
              desc: '可视化的树状流程配置逻辑清晰，操作简单，提供模板流程可参考'
            },
            {
              title: '业务意图配置零门槛',
              desc: '提供词库-句式&关键词两种业务意图配置，通过简单文本编辑、批量导入即可实现'
            },
            {
              title: '提供多种话后分析方式',
              desc: '提供呼后自动分类，支持多维度的筛选分析和全流程音频测听'
            }
          ]
        },
        {
          name: '资源丰富',
          icon: 'icon-adv-3.png',
          list: [
            {
              title: '大量成功案例可借鉴',
              desc: '覆盖银行、政务、能源等多个行业客户，积累了大量储蓄卡、信用卡、身份核实、线上查询、线上办理等场景经验'
            },
            {
              title: '行业沉淀可快速复用',
              desc: '深耕智能客服领域数多年，在业务运营过程沉淀和积累了丰富的行业知识，可以在业务开展中快速复用'
            }
          ]
        },
        {
          name: '服务专业',
          icon: 'icon-adv-4.png',
          list: [
            {
              title: '专业技术团队支撑',
              desc: '交付团队有丰富的人工智能项目实施经验，可以提供从产品定制到交付运营全流程的专业技术服务'
            },
            {
              title: '体系化运营方法论和培训',
              desc: '具备行业领先的运营方法论和培训体系，可以快速实现运营能力赋能和客户自主运营'
            }
          ]
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/icss-sfi/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
